(function($) {

var empty = true, character = null;
var pos = [{"margin-left": 0},{"margin-left": -150}, {"margin-left": -300},{"margin-left": -450},{"margin-left": -600}];


function getPos(temp){

	var cur = parseInt(temp.css("margin-left"));
	var temploc = cur/-150;

	if(temp.hasClass("flipX")){
		temploc += 4;
	}


	return temploc;
}

function setLoc(temp, tloc, tpos){

	if(tpos === 0){
	
		if(tloc > 4 && tloc !== 8){
			temp.addClass("flipX");
			tloc -= 4;
		}
		else if(tloc < 4 && temp.hasClass("flipX")){
			temp.removeClass("flipX");
		}
	}else{
		if(tloc > 4 && tloc !== 8){
			temp.addClass("flipX");
			tloc -= 4;
		}else{
			if(tloc === 4){
				if(temp.hasClass("flipX")){
					temp.removeClass("flipX");
				//tloc = 3;
				}
			}
		}
	}

	temp.css(pos[tloc]);

	return temp;

}

function load_ta(){


	/*$(".char-container").hover(function(){
		$(this).find(".la").addClass("showLA");
		$(this).find(".ra").addClass("showRA");
	});*/
function moveRight(){


				if(loc < 7){
					loc++;
				}
				else{
					loc = 0;
				}

				//character.css($pos[$loc]);
				character = setLoc(character, loc, 0);
				//console.log("RIGHT - Loc: " + loc);

			
			}

			function moveLeft(){

						if(loc > 0){
							loc--;
						}
						else{
							loc = 7;
						}

						//character.css($pos[$loc]);
						character = setLoc(character, loc, 1);
						//console.log("LEFT - Loc: " + loc);

			}


	$(".char-container").each(function(){

		
			$(this).find(".right").click(function(){

				character = $(this).parent('div').parent('div').find(".turnaround");
				loc = getPos(character);
				moveRight();
				
			});

			$(this).find(".left").click(function(){

				character = $(this).parent('div').parent('div').find(".turnaround");
				loc = getPos(character);
				moveLeft();
					   					
			});

		});


	$("body").keydown(function(e) {
				  if(e.keyCode === 37 && character != null) { // left
				      moveLeft();
				  }
				  else if(e.keyCode === 39 && character != null) { // right

					loc = getPos(character);
					moveRight();

				  }
	});



}



load_ta();

})(jQuery);



